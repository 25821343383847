// @ts-nocheck
import React from 'react';
import Section from '../Section';

import Card from '../../components/Card';
import styles from './ContactMeHomeSection.module.css';

import classNames from 'classnames';
import BtnChromaBorder from '../shared/BtnChromaBorder';

const cx = classNames.bind(styles);

export const ContactMeHomeSection = () => {
  return (
    <Section displaySection isAnimated className='container relative !pt-3'>
      <Card
        className='flex flex-col mb-3 justify-center !p-5 md:!pb-9 text-center bg-white items-justify'
        style={{ backgroundImage: 'url(./images/pattern_black.png)' }}
      >
        <div className='flex flex-col mx-auto my-auto md:max-w-3xl'>
          <h3
            className={cx(
              'text-[4.2em] md:text-9xl font-now text-bold text-center mx-auto scale-[1.05] leading-[2.7rem] mb-6',
              styles.holoText
            )}
          >
            <span className='tracking-[0.06em] md:tracking-[0.03em]'>
              LET’S WORK
            </span>
          </h3>
          <BtnChromaBorder
            href='/contact-me'
            className='block w-full p-[5px] text-2xl font-bold uppercase bg-white md:text-4xl font-now'
          >
            <span className='block w-full h-full p-3 bg-white'>
              <span className='relative z-10'> MESSAGE ME</span>
            </span>
          </BtnChromaBorder>
        </div>
      </Card>
    </Section>
  );
};
